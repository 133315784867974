<template>
  <div>
    <h2>{{ title || 'Onderaannemers winstmarge' }}</h2>

    <TableRittenWinstmargeOnderaanneming :list="data?.list" />
    <UITablePagination
      v-if="data"
      v-model:limit="apiData.limit"
      v-model:offset="apiData.offset"
      class="mt-2"
      :count="data.count"
    />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, toRefs } from 'vue'

import useGetApi from '@/hooks/useGetApi'

const props = defineProps({
  title: String,
  userId: [String, Number],
  klantId: [String, Number],
  facturatieId: [String, Number],
  facturatie: Boolean,
  date: [String, Number, Date],
})
const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))
const TableRittenWinstmargeOnderaanneming = defineAsyncComponent(() => import('@/components/Table/Ritten/WinstmargeOnderaanneming.vue'))

const { userId, klantId, facturatieId, facturatie, date } = toRefs(props)

const apiData = ref({
  type: 'ALLES',
  limit: 20,
  offset: 0,
})

const apiDataMerged = computed(() => {
  const result = { ...apiData.value }

  if (userId.value > 0) result.user_id = Number(userId.value)
  if (klantId.value > 0) result.klant_id = Number(klantId.value)
  if (facturatieId.value > 0) result.facturatie_id = Number(facturatieId.value)
  if (typeof facturatie.value !== 'undefined') result.facturatie = !!facturatie.value
  if (typeof date.value !== 'undefined') result.date = date.value

  return result
})

const { data } = useGetApi('RITTEN_WINSTMARGE_ONDERAANNEMING', apiDataMerged, { watch: true })
</script>
